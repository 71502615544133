@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Calibre';
    src:  url('fonts/calibre-web-light.woff') format('woff'),
          url('fonts/calibre-web-light.woff2'), format('woff2');
    font-weight: 300;
  }

  @font-face {
    font-family: 'Calibre';
    src:  url('fonts/calibre-web-regular.woff') format('woff'),
          url('fonts/calibre-web-regular.woff2') format('woff2');
    font-weight: 400;
  }

  @font-face {
    font-family: 'Calibre';
    src:  url('fonts/calibre-web-medium.woff') format('woff'),
          url('fonts/calibre-web-medium.woff2') format('woff2');
    font-weight: 500;  
  }

  @font-face {
    font-family: 'Calibre';
    src:  url('fonts/calibre-web-semibold.woff') format('woff'),
          url('fonts/calibre-web-semibold.woff2') format('woff2');
    font-weight: 600;  
  }
}

html,
body {
  color: #101010;
  margin: 0;
  padding: 0;
  font-family: 'Calibre', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  height: 100%;
}

/* General stylings. */

.search-results {
  position: absolute;
  margin-top: 31px;
  overflow: hidden;
  z-index: 999999999;
  width: 100%;
}

.search-results-list {
  border-top: none;
  border-color: #ffd5d4;
  box-shadow: 0 0 0 2px rgba(250, 167, 167, 0.12);
  border-width: 1;
}

.ais-InstantSearch {
  max-width: 960px;
  overflow: hidden;
  margin: 0 auto;
}

.ais-Hits-item {
  margin-bottom: 1em;
  width: calc(50% - 1rem);
}

.ais-Hits-item img {
  margin-right: 1em;
}

.hit-name {
  margin-bottom: 0.5em;
}

.hit-description {
  color: #888;
  font-size: 14px;
  margin-bottom: 0.5em;
}

.menuicon:hover {
  color: #f4b1af;
}

/** React Popper Tooltip overrides */

.tooltip-container {
  padding: 0 !important;
  margin-left: 0 !important;
  margin-bottom: 0 !important;
  margin-right: 0 !important;
  border-radius: 4px !important;
  border: none !important;
}

/** React Modal pop-up styling */

.ReactModal__Body--open {
  overflow: hidden;
}

.popup-overlay--base {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
  opacity: 0;
  outline: none;
  transition: opacity 200ms ease-in-out;
  z-index: 1000;
}

.popup-overlay--base.dark {
  background-color: rgba(0, 0, 0, 0.3);
}

.popup-overlay--after-open {
  opacity: 1;
}

.popup-overlay--before-close {
  opacity: 0;
}

.popup-content--base {
  @apply transform-gpu;
  @apply translate-y-full lg:-translate-x-1/2;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  transition-delay: initial;
}

.popup-content--after-open {
  @apply translate-y-0 lg:-translate-y-1/2;
}

.popup-content--before-close {
  @apply translate-y-full;
}
